import axios from 'axios';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import 'normalize.css';
import config from 'config/config.json';
import './index.scss';
import { clearOldLocalStorage } from './services/persistentStorage';

// Конфиг для линтинга mobx, что-то он не особо как-то помогает, ну или я не разобрался
// https://mobx.js.org/configuration.html#linting-options
configure({
  enforceActions: 'always',
  //   computedRequiresReaction: true,
  //   reactionRequiresObservable: true,
  //   observableRequiresReaction: true,
  //   disableErrorBoundaries: true,
});

clearOldLocalStorage();

(async function () {
  const isConfigNeeded =
    typeof window !== 'undefined' && !process.env.REACT_APP_LOCAL_DEV;
  console.log('isConfigNeeded', isConfigNeeded);

  if (isConfigNeeded) {
    const getAppConfig = await axios({
      method: 'get',
      url: '/config/config.json',
    });
    console.log('getAppConfig', getAppConfig);
    window.__APP_CONFIG = getAppConfig.data;
  } else {
    console.log('config', config);
    window.__APP_CONFIG = config;
  }
  const App = await import('./App').then((m) => m.default);
  ReactDOM.render(<App />, document.querySelector('#root'));
})();
